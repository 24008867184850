<template>
  <ClientOnly>
    <Script id="chat" async="async" type="text/javascript" :src="src" />
  </ClientOnly>
</template>

<script lang="ts" setup>
const src =
  "https://userlike-cdn-widgets.s3-eu-west-1.amazonaws.com/c19f906200144053b16061253bc5484963fb7428be424cbc8d407c87eb66846d.js";
</script>
